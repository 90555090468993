body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: medium;
  color: #495057;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.demoClass {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* height: 50px; */
}

.content-wrapper {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.content-header {
  padding-top: 10px;
  padding-left: 5px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.select.form-control {
  color: #495057
}


@keyframes slideInFromBottom {
  from {
    transform: translateY(50%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideInFromBottom 0.8s ease-in-out;
}

.card {
  animation: slideInFromBottom 0.8s ease-in-out;
}

.vertical-border th,
.vertical-border td {
  border-right: 1px solid #ddd;
}

.vertical-border th:last-child,
.vertical-border td:last-child {
  border-right: none;
}

::-webkit-scrollbar {
  width: 2px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #4B49AC;
}

::-webkit-scrollbar-thumb:hover {
  background: #7675c5;
}

@keyframes zoomIn {
  from {
    transform: scale(0.7);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.zoom-in {
  animation: zoomIn 0.6s ease-in-out;
}

.card {
  border-radius: 5px !important;
}

.content-wrapper {
  padding-top: 8px !important;
}

.table-sm th,
.table-sm td {
  padding: 7px;
}